import logo0 from "./logo0.svg";
import logo1 from "./logo1.svg";
import linkedin from "./linkedin.svg";
import instagram from "./instagram.svg";
import twitter from "./twitter.svg";
import backgroundLTR0 from "./backgroundLTR0.svg";
import backgroundRTL0 from "./backgroundRTL0.svg";
import backgroundLTR1 from "./backgroundLTR1.svg";
import backgroundRTL1 from "./backgroundRTL1.svg";
import mobileApp0 from "./mobile-app0.svg";
import mobileApp1 from "./mobile-app1.svg";
import mobileApp2 from "./mobile-app2.svg";
import pos0 from "./pos0.svg";
import background2 from "./background2.svg";
import background3 from "./background3.svg";
import background4 from "./background4.svg";
import fatoorah0 from "./fatoorah0.svg";
import mycash0 from "./mycash0.svg";
import mycash1 from "./mycash1.svg";
import mycash2 from "./mycash2.svg";
import mycash3 from "./mycash3.svg";
import mycash4 from "./mycash4.svg";

const images = {
  logo0,
  logo1,
  linkedin,
  instagram,
  twitter,
  backgroundLTR0,
  backgroundRTL0,
  backgroundLTR1,
  backgroundRTL1,
  mobileApp0,
  mobileApp1,
  mobileApp2,
  pos0,
  background2,
  background3,
  background4,
  mycash0,
  mycash1,
  mycash2,
  mycash3,
  mycash4,
  fatoorah0,
};
export default images;
