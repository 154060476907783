import { Box } from "@mui/material";
import React from "react";
import Container3 from "../common/containers/Container3";
import Box01 from "../common/boxes/Box01";
import { useTranslation } from "react-i18next";
import commonStyles from "../../assets/styles/common";

const Services4 = () => {
  const { t } = useTranslation();
  return (
    <Box component={"section"}>
      <Container3 pb={commonStyles.py0.py}>
        <Box01
          title={t("home.mobile-app.title")}
          subTitle={t("home.mobile-app.sub-title")}
          description={t("home.mobile-app.description")}
          wordToHighlight={t("home.mobile-app.wordToHighlight")}
          descriptionWordToHighlight={t("services.wordToHighlight1")}
          bgcolor={"background.paper"}
          py={commonStyles.py0.py}
          borderRadius={commonStyles.borderRadius}
        />
      </Container3>
    </Box>
  );
};

export default Services4;
