import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Card06 from "../common/cards/Card06";
import Container3 from "../common/containers/Container3";

const Services2 = () => {
  const { t } = useTranslation();
  const items = [
    t("services.service0"),
    t("services.service1"),
    t("services.service2"),
    t("services.service3"),
  ];

  return (
    <Box component={"section"}>
      <Container3>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: { xs: "12px", sm: "18px", md: "24px", lg: "32px" },
            justifyContent: "center",
          }}
        >
          {items.map((item, index) => (
            <Card06 key={index} text={item} />
          ))}
        </Box>
      </Container3>
    </Box>
  );
};

export default Services2;
