import React from "react";
import HomeComponent from "../../components/home/HomeComponent";

const Home = () => {
  return (
    <>
      <HomeComponent />
    </>
  );
};

export default Home;
