import { createTheme } from "@mui/material";
import React, { createContext, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const MainThemeContext = createContext({
  theme: {},
  mode: "light",
  setMode: () => {},
  changeLanguage: () => {},
  homeBriefs: null,
});
const MainThemeProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const dir = i18n.dir();

  const changeLanguage = () => {
    // i18n.changeLanguage(dir === "rtl" ? "en" : "ar");

    setTimeout(() => {
      i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
    }, 300);
  };
  const lang = i18n.language;
  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", dir);
  }, [dir]);

  const [mode, setMode] = useState("light");

  const theme = React.useMemo(
    () =>
      createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            container: 1440,
          },
        },
        direction: dir,
        palette: {
          mode: mode,
          primary: {
            main: "#59935A",
          },
          secondary: {
            main: "rgba(0, 0, 0, 0.60)",
          },
          background: {
            main: "rgba(247,247,247,1)",
            secondary: "#CBDCCB",
            btns: "#3A643B",
            common: "#D5E3D5",
            img: "#424243",
          },
          text: {
            primary: mode === "light" ? "#000" : "#fff",
            secondary:
              mode === "light"
                ? "rgba(0, 0, 0, 0.80)"
                : "rgba(255, 255, 255, 0.7)",
            img: "#D9DDE8",
          },
          common: {
            inputsBorder: "#DDDDDD",
          },
        },

        typography: {
          h2: {
            fontWeight: 600,
            fontSize: 14,
            textAlign: "center",
            textTransform: "capitalize",
          },
          h3: {
            fontWeight: 600,
            fontSize: 16,
            textTransform: "capitalize",
          },
          h4: {
            fontSize: 12,
            fontWeight: 700,
            textTransform: "capitalize",
          },
          h5: {
            fontSize: 16,
            fontWeight: 700,
            textTransform: "capitalize",
          },
          h6: {
            fontWeight: 600,
            fontSize: 24,
            textTransform: "capitalize",
          },
          body1: {
            fontWeight: 500,
            fontSize: 14,
          },
          body2: {
            fontSize: 16,
            fontWeight: 500,
          },
          caption: {
            fontSize: 12,
            fontWeight: 600,
          },
          // button: {
          //   textTransform: "none",
          //   fontSize: 8,
          //   fontWeight: 700,
          // },
          fontFamily:
            lang === "en"
              ? "galano-grotesque, sans-serif"
              : "Readex Pro, sans-serif",
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: "none",

                // width: "auto",
                // height: "auto",
                padding: 0,
                // borderRadius: 30,
                // fontSize: "24px",
                // fontWeight: 500,
              },
            },
          },
        },
      }),
    [mode, dir]
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );
  const homeBriefs = useRef(null);
  return (
    <MainThemeContext.Provider
      value={{
        theme,
        changeLanguage,
        colorMode,
        mode,
        homeBriefs,
      }}
    >
      {children}
    </MainThemeContext.Provider>
  );
};

export default MainThemeProvider;
export const useMainThemeContext = () => {
  return useContext(MainThemeContext);
};
