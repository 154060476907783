import { Box, Stack } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";
import Title01 from "../texts/Title01";
import Description from "../texts/Description";

const Card02 = ({ img, alt, title, description }) => {
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        borderRadius: commonStyles.borderRadius,
        width: { xs: 280, sm: 400, md: 633, lg: 633 },
        // height: { xs: 1065, md: 1065, lg: 1065 },
      }}
    >
      <Stack
        sx={{
          py: commonStyles.py1.py,
          px: commonStyles.px1,
          rowGap: { xs: "8px", sm: "12px", md: "18px", lg: "24px" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "100%", lg: 552 },
            height: { xs: 120, md: 180, lg: 288 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            img: {
              objectFit: "contain",
              height: "100%",
            },
          }}
        >
          <Box component={"img"} src={img} alt={alt} />
        </Box>
        <Title01 text={title} width={{ xs: "100%", md: "100%", lg: "537px" }} />
        <Description
          text={description}
          textAlign="center"
          lineHeight={{ xs: "18px", sm: "24px", md: "32px", lg: "48px" }}
          width={{ xs: "100%", md: "100%", lg: "537px" }}
          m="auto"
        />
      </Stack>
    </Box>
  );
};

export default Card02;
