import { Box } from "@mui/material";
import React from "react";
import images from "../../../assets/images/images";
import Card04 from "../../common/cards/Card04";
import Box01 from "../../common/boxes/Box01";
import { useTranslation } from "react-i18next";
import commonStyles from "../../../assets/styles/common";
import Container4 from "../../common/containers/Container4";

const Word2 = () => {
  const { t } = useTranslation();
  return (
    <Box component={"section"}>
      <Container4>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: "10px", sm: "12px", md: "24px", lg: "32px" },
            // flexWrap: "wrap",
          }}
        >
          <Card04 img={images.pos0} />
          <Box01
            btnText={t("home.btn-portfolio")}
            to="./portfolio"
            subTitle={t("home.sub-title2")}
            wordToHighlight={t("home.wordToHighlight2")}
            bgcolor={"background.paper"}
            borderRadius={commonStyles.borderRadius}
            width={{ xs: "100%", md: "100%", lg: 691 }}
            sectionDescriptionWidth={{ xs: "100%", md: "100%", lg: 611 }}
            textAlign="center"
            alignItems="center"
            justifyContent={"center"}
            height={{ xs: "100%", md: "100%", lg: 633 }}
            py={commonStyles.py0.py}
          />
        </Box>
        <Box01
          title={t("home.title3")}
          btnText={t("home.btn-contact")}
          to="./contact"
          subTitle={t("home.sub-title3")}
          wordToHighlight={t("home.wordToHighlight3")}
          sectionDescriptionWidth={{ xs: "100%", md: "100%", lg: 1000 }}
          textAlign="center"
          alignItems="center"
          py={commonStyles.py0.py}
        />
      </Container4>
    </Box>
  );
};

export default Word2;
