import { Avatar, Box, Typography } from "@mui/material";
import React from "react";

const CardDescription = ({
  icon,
  title,
  year,
  isWhite = false,
  avatarWidthHeight = { xs: 100, md: 100, lg: 100 },
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: { xs: "14px", sm: "20px", md: "26px", lg: "32px" },
        justifyContent: "start",
        width: "100%",
        py: { xs: 0 },
      }}
    >
      <Avatar
        alt={title}
        src={icon}
        sx={{
          width: avatarWidthHeight,
          height: avatarWidthHeight,
        }}
      />
      <Box>
        <Typography
          color={!isWhite ? "text.primary" : "background.paper"}
          gutterBottom
          variant="h3"
          sx={{
            fontSize: !isWhite
              ? { xs: 12, sm: 18, md: 24, lg: 32 }
              : { xs: 10, sm: 14, md: 22, lg: 28 },
          }}
        >
          {title}
        </Typography>
        <Typography
          color={!isWhite ? "secondary.main" : "text.img"}
          sx={{
            textAlign: "start",
            fontSize: !isWhite
              ? { xs: 10, sm: 12, md: 18, lg: 24 }
              : { xs: 8, sm: 10, md: 16, lg: 22 },
            fontWeight: 600,
          }}
        >
          {year}
        </Typography>
      </Box>
    </Box>
  );
};

export default CardDescription;
