import { Box } from "@mui/material";
import React from "react";
import Container0 from "../common/containers/Container0";
import Box01 from "../common/boxes/Box01";
import { useTranslation } from "react-i18next";

const Services0 = () => {
  const { t } = useTranslation();
  return (
    <Box component={"section"}>
      <Container0>
        <Box01
          title={t("services.title0")}
          subTitle={t("services.sub-title0")}
          description={t("services.description0")}
          wordToHighlight={t("services.wordToHighlight0")}
          descriptionWordToHighlight={t("services.wordToHighlight1")}
        />
      </Container0>
    </Box>
  );
};

export default Services0;
