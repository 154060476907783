import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Card05 from "../common/cards/Card05";
import icons from "../../assets/icons/icons";
import images from "../../assets/images/images";
import Container3 from "../common/containers/Container3";
import commonStyles from "../../assets/styles/common";

const Services1 = () => {
  const { t, i18n } = useTranslation();
  const dir = i18n.dir();

  return (
    <Box component={"section"}>
      <Container3>
        <Box
          component={"img"}
          src={images.logo1}
          alt="mountasher"
          sx={{
            position: "absolute",
            top: { xs: -18, sm: -25, md: -20, lg: -30 },
            left: dir === "rtl" && { xs: 50, sm: 70, md: 130, lg: 140 },
            right: dir === "ltr" && { xs: 50, sm: 70, md: 130, lg: 140 },
            width: { xs: 79, sm: 119, md: 139, lg: 189 },
          }}
        />
        <Card05
          px={commonStyles.px}
          icon={icons.services}
          title={t("services.title1")}
          width={"100%"}
          description={t("services.description1")}
          wordToHighlight={t("services.wordToHighlight1")}
        />
      </Container3>
    </Box>
  );
};

export default Services1;
