import React from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import icons from "../../../assets/icons/icons";
import { useFormikContext } from "formik";
import commonStyles from "../../../assets/styles/common";

const RootContainer = styled("label")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  //   justifyContent: "center",

  borderStyle: "dashed",
  borderWidth: 2,
  borderRadius: commonStyles.borderRadius1,
  cursor: "pointer",
});

const Input = styled("input")({
  display: "none",
});

const Input1 = ({
  inputName,
  inputLabel,
  width = { xs: "100%", lg: 1056 },
  height = { xs: 80, sm: 110, md: 160, lg: 231 },
}) => {
  const { errors, touched, setFieldValue, values } = useFormikContext();
  return (
    <Stack sx={{ gap: { xs: "8px", sm: "12px", md: "18px", lg: "24px" } }}>
      {inputLabel && (
        <Typography
          sx={{ fontSize: { xs: 12, sm: 16, md: 24, lg: 32 }, fontWeight: 600 }}
        >
          {inputLabel}
        </Typography>
      )}
      <RootContainer
        htmlFor={inputName}
        sx={{
          width: width,
          height: height,
          gap: { xs: "8px", sm: "12px", md: "18px", lg: "24px" },
          py: commonStyles.py0.py,
        }}
      >
        <Input
          name={inputName}
          id={inputName}
          error={errors?.[inputName] && touched?.[inputName]}
          helperText={
            errors?.[inputName] && touched?.[inputName]
              ? errors?.[inputName]
              : ""
          }
          onChange={(e) => {
            console.log(e.target.files[0]);
            setFieldValue(inputName, e.target.files[0]);
          }}
          type="file"
          accept="application/pdf"
        />
        <Box
          component={"img"}
          src={icons.upload0}
          alt="upload"
          sx={{
            width: { xs: 20, sm: 40, md: 60, lg: 72 },
            height: { xs: 20, sm: 40, md: 60, lg: 72 },
          }}
        />
        {values[inputName]?.name ? (
          <Typography
            sx={{
              color: "secondary.main",
              fontSize: { xs: 12, sm: 14, md: 18, lg: 24 },
            }}
          >
            {values[inputName]?.name}
          </Typography>
        ) : (
          <Typography
            sx={{
              color: "secondary.main",
              fontSize: { xs: 12, sm: 14, md: 18, lg: 24 },
            }}
          >
            Drag & Drop Your Resume/CV Here{" "}
            <Typography
              variant={"span"}
              sx={{ color: "background.btns", fontWeight: 700 }}
            >
              OR{" "}
            </Typography>
            <Typography
              variant={"span"}
              sx={{
                color: "background.btns",
                fontWeight: 700,
                textDecoration: "underline",
              }}
            >
              Browse
            </Typography>
          </Typography>
        )}
      </RootContainer>
    </Stack>
  );
};

export default Input1;
