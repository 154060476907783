import { Box } from "@mui/material";
import React from "react";
import images from "../../assets/images/images";
import Lottie from "lottie-react";
import Portfolio0 from "../../assets/animations/Portfolio0.json";
import Container3 from "../common/containers/Container3";
const Portfolio1 = () => {
  return (
    <Box component={"section"}>
      <Container3>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${images.background4})`,
            height: { xs: 110, sm: 140, md: 180, lg: 241 },
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            // backgroundSize:"",
          }}
        >
          <Box
            component={Lottie}
            animationData={Portfolio0}
            sx={{
              width: { xs: 320, sm: 460, md: 520, lg: 662 },
              height: "inherit",
            }}
          />
        </Box>
      </Container3>
    </Box>
  );
};

export default Portfolio1;
