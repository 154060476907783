import React from "react";
import ContactForm from "./ContactForm";
import Contact0 from "./Contact0";
import { useTranslation } from "react-i18next";
import MainTitle from "../common/texts/MainTitle";
import Contact1 from "./Contact1";
import useMount from "../../hooks/useMount";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { componentFade } from "../../utils/animationsVariants";

const ContactComponent = () => {
  const { t } = useTranslation();
  useMount({ documentTitle: t("contact.bar-title") });

  return (
    <>
      <Box
        component={motion.section}
        variants={componentFade}
        initial="hidden"
        animate="showComponets"
        exit="exit"
      >
        <MainTitle text={t("contact.main-title")} />
        <Contact0 />
        <Contact1 />
        <ContactForm />
      </Box>
    </>
  );
};

export default ContactComponent;
