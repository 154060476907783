import { Box, Grid } from "@mui/material";
import React from "react";
import Card05 from "../../common/cards/Card05";
import { useTranslation } from "react-i18next";
import icons from "../../../assets/icons/icons";
import commonStyles from "../../../assets/styles/common";
import Container4 from "../../common/containers/Container4";

const Phase = () => {
  const { t } = useTranslation();
  const items = [
    {
      icon: icons.phase01,
      title: t("home.phase.title0"),
      description: t("home.phase.description0"),
    },
    {
      icon: icons.phase02,
      title: t("home.phase.title1"),
      description: t("home.phase.description1"),
    },
    {
      icon: icons.phase03,
      title: t("home.phase.title2"),
      description: t("home.phase.description2"),
    },
    {
      icon: icons.phase04,
      title: t("home.phase.title3"),
      description: t("home.phase.description3"),
    },
    {
      icon: icons.phase05,
      title: t("home.phase.title4"),
      description: t("home.phase.description4"),
    },
    {
      icon: icons.phase06,
      title: t("home.phase.title5"),
      description: t("home.phase.description5"),
    },
  ];
  return (
    <Box component={"section"}>
      <Container4 pb={commonStyles.py0.py}>
        <Grid
          container
          sx={{
            display: "flex",
            rowGap: { xs: "16px", md: "28px", lg: "40px" },
            // columnGap: { xs: "8px", md: "12px" },
            flexWrap: "wrap",
            justifyContent: {
              xs: "flex-start",
              md: "flex-start",
              lg: "space-between",
            },
          }}
        >
          {items.map((item, index) => (
            <Grid key={index} item lg={5.7} xs={12}>
              <Card05
                title={item.title}
                description={item.description}
                icon={item.icon}
                wordToHighlight={t("home.wordToHighlight0")}
                px={commonStyles.px}
              />
            </Grid>
          ))}
        </Grid>
      </Container4>
    </Box>
  );
};

export default Phase;
