import { Box, Container, Divider, Grid } from "@mui/material";
import React from "react";
import commonStyles from "../../assets/styles/common";
import LogoAndText from "./LogoAndText";
import Column from "./Column";
import { useTranslation } from "react-i18next";
import RightsAndSocial from "./RightsAndSocial";

const Footer = () => {
  const { t } = useTranslation();
  const items = [t("footer.call-us"), "+966 59 900 7745"];
  const links1 = [
    { text: t("footer.about"), to: "./about" },
    { text: t("footer.services"), to: "./services" },
    { text: t("footer.portfolio"), to: "./portfolio" },
    { text: t("footer.careers"), to: "./careers" },
  ];
  const links2 = [
    { text: t("footer.terms"), to: "./terms" },
    { text: t("footer.privacy-policy"), to: "./privacy-policy" },
    { text: t("footer.cookies-policy"), to: "./cookies-policy" },
  ];
  return (
    <Box
      component={"section"}
      sx={{
        // px: commonStyles.px,
        // pt: commonStyles.py0.py,
        pb: commonStyles.py1.py,
      }}
    >
      <Container maxWidth="container" sx={{ px: commonStyles.px }}>
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: commonStyles.borderRadius,
            py: commonStyles.py1.py,
            px: commonStyles.px0,
          }}
        >
          <Grid container sx={{ rowGap: "12px" }}>
            <Grid item xs={12} md={6}>
              <LogoAndText />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <Column title={t("footer.contact")} items={items} />
              <Column title={t("footer.company")} links={links1} />
              <Column title={t("footer.legal")} links={links2} />
            </Grid>
          </Grid>
          <Divider
            sx={{
              mt: commonStyles.py1.py,
              mb: commonStyles.py0.py,
              bgcolor: "background.common",
            }}
          />
          <RightsAndSocial />
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
