import React from "react";
import { useTranslation } from "react-i18next";
import MainTitle from "../common/texts/MainTitle";
import Services0 from "./Services0";
import Services1 from "./Services1";
import Services2 from "./Services2";
import Services3 from "./Services3";
import Services4 from "./Services4";
import Slider01 from "../sliders/Slider01";
import Services5 from "./Services5";
import Services6 from "./Services6";
import useMount from "../../hooks/useMount";
import { motion } from "framer-motion";
import { Box } from "@mui/material";
import { componentFade } from "../../utils/animationsVariants";

const ServicesComponent = () => {
  const { t } = useTranslation();
  useMount({ documentTitle: t("services.bar-title") });

  return (
    <>
      <Box
        component={motion.section}
        variants={componentFade}
        initial="hidden"
        animate="showComponets"
        exit="exit"
      >
        <MainTitle text={t("header.services")} />
        <Services0 />
        <Services1 />
        <Services2 />
        <Services3 />
        <Services4 />
        <Slider01 isHasNextBtn={true} />
        <Services5 />
        <Services6 />
      </Box>
    </>
  );
};

export default ServicesComponent;
