import { useState, useEffect } from "react";
const useFadeElement = (elements) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true); // Fade in on mount

    const timeout = setTimeout(() => {
      setIsVisible(false); // Fade out after delay
    }, 2000);

    return () => clearTimeout(timeout); // Cancel fade-out on unmount
  }, [currentIndex]);

  useEffect(() => {
    if (elements.length) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % elements.length); // Loop through array
        return () => clearInterval(interval);
      }, 4000);
    }
  }, [elements.length]);
  const currentText = elements[currentIndex];

  return { isVisible, currentText };
};
export default useFadeElement;
