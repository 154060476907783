import { Container } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";

const Container4 = ({ children, bgcolor, borderRadius, pb }) => {
  return (
    <Container
      sx={{
        height: "100%",
        px: commonStyles.px4,
        pt: commonStyles.py0.py,
        pb,
        bgcolor,
        borderRadius,
        position: "relative",
      }}
      maxWidth="container"
    >
      {children}
    </Container>
  );
};

export default Container4;
