import { Box } from "@mui/material";
import React from "react";
import Container3 from "../common/containers/Container3";
import Box01 from "../common/boxes/Box01";
import { useTranslation } from "react-i18next";
import commonStyles from "../../assets/styles/common";

const Contact0 = () => {
  const { t } = useTranslation();

  return (
    <Box component={"section"}>
      <Container3>
        <Box01
          title={t("contact.title0")}
          subTitle={t("contact.sub-title0")}
          wordToHighlight={t("contact.wordToHighlight0")}
          borderRadius={commonStyles.borderRadius}
          py={commonStyles.py0.py}
        />
      </Container3>
    </Box>
  );
};

export default Contact0;
