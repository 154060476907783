import { Box, IconButton } from "@mui/material";
import React from "react";
import HeaderLogo from "./HeaderLogo";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

const HeaderSmall = ({ isDrawerOpened, setIsDrawerOpened }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: { xs: "flex", md: "none" },
        justifyContent: "space-between",
        alignItems: "center",
        pt: "18px",
      }}
    >
      <HeaderLogo trigger={true} />
      {!isDrawerOpened && (
        <IconButton
          disableRipple
          onClick={() => setIsDrawerOpened(!isDrawerOpened)}
          sx={{
            color: "primary.main",
            transition: "all .2s",
            display: { xs: "flex", md: "none" },
            ":hover": {
              bgcolor: "transparent",
              color: "primary.main",
            },
          }}
        >
          {isDrawerOpened ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      )}
    </Box>
  );
};

export default HeaderSmall;
