import { Box } from "@mui/material";
import React from "react";
import Container3 from "../common/containers/Container3";
import images from "../../assets/images/images";
import Lottie from "lottie-react";
import services0 from "../../assets/animations/services0.json";

const Services3 = () => {
  return (
    <Box component={"section"}>
      <Container3>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${images.background3})`,
            height: { xs: 170, sm: 280, md: 320, lg: 457 },
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            // backgroundSize:"",
          }}
        >
          <Box
            component={Lottie}
            animationData={services0}
            sx={{
              width: { xs: 320, sm: 440, md: 520, lg: 633 },
              height: "inherit",
            }}
          />
        </Box>
      </Container3>
    </Box>
  );
};

export default Services3;
