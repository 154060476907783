import React from "react";
import MainLayout from "../layouts/MainLayout";
import { Box, Typography } from "@mui/material";
import Container3 from "../common/containers/Container3";
import { useTranslation } from "react-i18next";
import commonStyles from "../../assets/styles/common";

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <MainLayout error={true}>
        <Box>
          <Container3 pb={commonStyles.py0.py}>
            <Box>
              <Typography
                sx={{ fontSize: { xs: 24, md: 36, lg: 48 }, fontWeight: 700 }}
              >
                {t("error.title0")}
              </Typography>
              <Typography
                sx={{ fontSize: { xs: 16, md: 24, lg: 32 }, fontWeight: 500 }}
              >
                {t("error.description0")}
              </Typography>
            </Box>
          </Container3>
        </Box>
      </MainLayout>
    </>
  );
};

export default PageNotFound;
