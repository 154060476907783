import React from "react";
import { Box } from "@mui/material";
import WorkImg from "./WorkImg";
import Container3 from "../../common/containers/Container3";
import Work2 from "./Work2";
import { useWorkContext } from "../../../context/WorkContext";

const Work1 = () => {
  const { work, setOpen, setCurrentImg } = useWorkContext();
  const handleClick = (index) => {
    setOpen(true);
    setCurrentImg(index);
  };

  return (
    <Box component={"section"}>
      <Container3>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: { xs: "12px", sm: "18px", md: "24px", lg: "32px" },
            justifyContent: "center",
          }}
        >
          {work.workImgs?.map((item, index) => (
            <WorkImg
              key={index}
              src={item}
              alt="mycash"
              index={index}
              onClick={() => handleClick(index)}
            />
          ))}
        </Box>
      </Container3>
      {work.workImgs?.length && <Work2 />}
    </Box>
  );
};

export default Work1;
