import React from "react";
import NavBarLink from "../common/links/NavBarLink";
import { Box } from "@mui/material";
import navStyles from "../../assets/styles/nav";
import { useTranslation } from "react-i18next";

const HeaderLinks = ({ trigger = false }) => {
  const { t } = useTranslation();
  const pages = [
    { to: "/", title: t("header.home"), end: true },
    { to: "about", title: t("header.about"), end: false },
    { to: "services", title: t("header.services"), end: false },
    { to: "portfolio", title: t("header.portfolio"), end: false },
    { to: "contact", title: t("header.contact"), end: false },
  ];
  const NavBarLinks = () => {
    return (
      <>
        {pages.map((page, index) => (
          <NavBarLink
            key={index}
            to={page.to}
            end={page.to}
            title={page.title}
          />
        ))}
      </>
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: trigger ? "space-between" : "center",
        gap: navStyles.gap,
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
      }}
    >
      <NavBarLinks />
    </Box>
  );
};
export default HeaderLinks;
