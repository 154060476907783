import { Stack } from "@mui/material";
import React from "react";
import SectionTitle from "../texts/SectionTitle";
import SectionDescription from "../texts/SectionDescription";
import Button01 from "../buttons/Button01";
import commonStyles from "../../../assets/styles/common";
import Description1 from "../texts/Description1";

const Box02 = ({
  title,
  description,
  width = { xs: "100%", md: "100%", lg: "100%" },
  btnText,
  to,
  subTitle,
  textAlign,
  alignItems,
  bgcolor,
  borderRadius,
  py,
  wordToHighlight,
  descriptionWidth,
  descriptionM,
  height,
}) => {
  return (
    <Stack
      sx={{
        rowGap: { xs: "12px", md: "18px", lg: "24px" },
        alignItems: alignItems,
        // justifyContent: "center",
        bgcolor: bgcolor,
        borderRadius: borderRadius,
        py,
        px: commonStyles.px0,
        height,
      }}
    >
      <SectionTitle text={title} />
      <SectionDescription
        text={subTitle}
        textAlign={textAlign}
        width={width}
        wordToHighlight={wordToHighlight}
      />
      <Description1
        text={description}
        width={descriptionWidth}
        m={descriptionM}
      />
      {btnText && <Button01 text={btnText} to={to} />}
    </Stack>
  );
};

export default Box02;
