import { Box } from "@mui/material";
import React from "react";
import Container3 from "../common/containers/Container3";
import Lottie from "lottie-react";
import contact0 from "../../assets/animations/contact0.json";
import images from "../../assets/images/images";
const Contact1 = () => {
  return (
    <Box component={"section"}>
      <Container3>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${images.background4})`,
            height: { xs: 70, sm: 100, md: 110, lg: 150 },
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            // backgroundSize:"",
            position: "relative",
          }}
        >
          <Box
            component={Lottie}
            animationData={contact0}
            sx={{
              width: { xs: 170, sm: 280, md: 320, lg: 500 },
              height: { xs: 300, sm: 310, md: 410, lg: 500 },
              position: "absolute",
            }}
          />
        </Box>
      </Container3>
    </Box>
  );
};

export default Contact1;
