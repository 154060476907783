import { Box, Stack } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";
import Title02 from "../texts/Title02";

const Card03 = ({ img, alt, title }) => {
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        borderRadius: commonStyles.borderRadius,
        width: { xs: 480, md: 480, lg: 480 },
        // height: { xs: 1065, md: 1065, lg: 1065 },
      }}
    >
      <Stack
        sx={{
          py: commonStyles.py1.py,
          px: commonStyles.px2,
          rowGap: { xs: "24px", md: "24px", lg: "24px" },
        }}
      >
        <Box
          sx={{
            width: { xs: 425, md: 425, lg: 425 },
            height: { xs: 240, md: 240, lg: 240 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            img: {
              objectFit: "contain",
            },
          }}
        >
          <Box component={"img"} src={img} alt={alt} />
        </Box>
        <Title02 text={title} width={{ xs: 350, md: 350, lg: 350 }} />
      </Stack>
    </Box>
  );
};

export default Card03;
