import { Typography } from "@mui/material";
import React from "react";

const SectionTitle = ({
  text = "",
  isFadeElement = false,
  isVisible,
  textAlign,
}) => {
  const sectionTitleStyle = {
    color: "secondary.main",
    fontSize: { xs: 14, sm: 18, md: "24px", lg: "32px" },
    textAlign,
  };
  const fadeSectionTitleStyle = {
    ...sectionTitleStyle,
    opacity: isVisible ? 1 : 0,
    transition: "opacity 2.5s ease-in-out",
    textAlign: "center",
  };
  if (!isFadeElement) {
    return (
      <Typography variant="h3" sx={sectionTitleStyle}>
        {`<${text}/>`}
      </Typography>
    );
  } else {
    return (
      <Typography variant="h3" sx={fadeSectionTitleStyle}>
        {`<${text}/>`}
      </Typography>
    );
  }
};

export default SectionTitle;
