import { Box, CircularProgress } from "@mui/material";
import React from "react";
import Container3 from "../common/containers/Container3";
import { Formik } from "formik";
import contactSchema from "../../assets/inputsSchema/contactSchema";
import Input0 from "../common/inputs/Input0";
import commonStyles from "../../assets/styles/common";
import Card05 from "../common/cards/Card05";
import { useTranslation } from "react-i18next";
import Button01 from "../common/buttons/Button01";
import sendMessage from "../../apis/sendMessage";

const ContactForm = () => {
  const { t } = useTranslation();
  const inputs = [
    {
      inputName: "name",
      inputLabel: t("contact.name"),
      multiline: false,
      placeholder: t("contact.name-plceholder"),
      type: "text",
    },
    {
      inputName: "email",
      inputLabel: t("contact.email"),
      multiline: false,
      placeholder: t("contact.email-plceholder"),
      type: "text",
    },
    {
      inputName: "phone",
      inputLabel: t("contact.phone"),
      multiline: false,
      placeholder: t("contact.phone-plceholder"),
      type: "text",
    },

    {
      inputName: "message",
      inputLabel: t("contact.message"),
      multiline: true,
      placeholder: t("contact.message-plceholder"),
      type: "text",
    },
  ];
  const initialValues = {
    email: "",
    name: "",
    phone: "",
    message: "",
  };
  return (
    <Box>
      <Container3 pb={commonStyles.py0.py}>
        <Formik
          initialValues={initialValues}
          validationSchema={contactSchema}
          onSubmit={(values, actions) => sendMessage(values, actions)}
        >
          {(props) => (
            <Box
              component={"form"}
              onSubmit={props.handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                gap: { xs: "14px", sm: "24px", md: "32px", lg: "48px" },
                py: commonStyles.py1.py,
                px: commonStyles.px4,
                borderRadius: commonStyles.borderRadius,
                bgcolor: "background.paper",
              }}
            >
              <Card05
                description={t("contact.description1")}
                fontSize={{ xs: 12, sm: 16, md: 24, lg: 32 }}
                title={t("contact.title1")}
                wordToHighlight={t("contact.wordToHighlight1")}
                py={{ xs: 0 }}
                width={"auto"}
              />
              {inputs.map((input, index) => (
                <Input0
                  key={index}
                  inputLabel={input.inputLabel}
                  inputName={input.inputName}
                  multiline={input.multiline}
                  placeholder={input.placeholder}
                  type={input.type}
                />
              ))}

              {props.isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button01 text={t("contact.send")} />
              )}
            </Box>
          )}
        </Formik>
      </Container3>
    </Box>
  );
};

export default ContactForm;
