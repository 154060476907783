import * as yup from "yup";
// const passwordRules = /^(?=.*\d) (?=.*[a-z]) (?=.*[A-Z]).{5,}$/;
// min 5 Characters, 1 upper case letter, 1 lower case letter, 1 numeric digit
const jobSchema = yup.object().shape({
  email: yup.string().email(),
  name: yup.string().required(),
  phone: yup.number().typeError("phone should be a number").required(),
  cv: yup.string().required(),
  mimetype: yup.string().oneOf(["application/pdf"]).required(),
  size: yup
    .number()
    .max(5 * 1024 * 1024, "File size exceeds 5 MB.")
    .required(),
  message: yup.string(),
});
export default jobSchema;
