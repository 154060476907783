import React from "react";
import { Box, Container, Stack } from "@mui/material";
import BannerAnimation from "./BannerAnimation";
import commonStyles from "../../../assets/styles/common";
import SectionTitle from "../../common/texts/SectionTitle";
import useFadeElement from "../../../hooks/useFadeElement";
import { useTranslation } from "react-i18next";
import SectionDescription from "../../common/texts/SectionDescription";
import ButtonToSection from "../../common/buttons/ButtonToSection";
import { useMainThemeContext } from "../../../context/MainThemeContext";
// import highlightWords from "../../../utils/highlightWords";
const stackStyle = {
  bgcolor: "background.secondary",
  borderRadius: commonStyles.borderRadius,
  minHeight: { xs: 300, md: 500, lg: 743 },
  py: commonStyles.py0.py,
  alignItems: "center",
  rowGap: { xs: "8px", md: "12px", lg: "16px" },
};

const Banner = () => {
  const { homeBriefs } = useMainThemeContext();
  const { t } = useTranslation();
  const texts = [
    {
      title: t("home.banner.title0"),
      description: t("home.banner.description0"),
      wordToHighlight: t("home.banner.wordToHighlight0"),
    },
    {
      title: t("home.banner.title1"),
      description: t("home.banner.description1"),
      wordToHighlight: t("home.banner.wordToHighlight1"),
    },
    {
      title: t("home.banner.title2"),
      description: t("home.banner.description2"),
      wordToHighlight: t("home.banner.wordToHighlight2"),
    },
  ];
  const { currentText, isVisible } = useFadeElement(texts);
  return (
    <>
      <Box component={"section"} sx={{ pt: { xs: "6px", lg: "0px" } }}>
        <Container
          sx={{
            height: "100%",
            px: commonStyles.px,
          }}
          maxWidth="container"
        >
          <Stack sx={stackStyle}>
            <BannerAnimation />
            <SectionTitle
              text={currentText.title}
              isVisible={isVisible}
              isFadeElement={true}
            />
            <SectionDescription
              text={currentText.description}
              isVisible={isVisible}
              isFadeElement={true}
              wordToHighlight={currentText.wordToHighlight}
            />
            <ButtonToSection elementRef={homeBriefs} />
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Banner;
