import React, { createContext, useContext, useState } from "react";
import images from "../assets/images/images";
import { useParams } from "react-router-dom";
import icons from "../assets/icons/icons";

// import axios from "axios";
const WorkContext = createContext({
  work: {},
  setWork: () => {},
  open: false,
  setOpen: () => {},
  currentImg: 0,
  setCurrentImg: () => {},
});
const WorkProvider = ({ children }) => {
  const { id } = useParams();
  const { mycash0, mycash1, mycash2, mycash3, mycash4 } = images;
  const works = [
    {
      id: 1,
      title: "myCash - ماي كاش",
      year: 2022,
      icon: icons.mycash0,
      workImgs: [mycash0, mycash1, mycash2, mycash3, mycash4],
    },
    {
      id: 2,
      title: "Fatoorah App - تطبيق فاتورة",
      year: 2021,
      icon: icons.fatoorah0,
      workImgs: [],
    },
  ];
  const [work, setWork] = useState(works?.find((item) => item.id === +id));
  const [open, setOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState(0);
  return (
    <WorkContext.Provider
      value={{ work, open, setOpen, currentImg, setCurrentImg }}
    >
      {children}
    </WorkContext.Provider>
  );
};
// make sure use
export default WorkProvider;
export const useWorkContext = () => {
  return useContext(WorkContext);
};
