import { Typography, useTheme } from "@mui/material";
import React from "react";
import highlightWords from "../../../utils/highlightWords";
import parse from "html-react-parser";

const Description = ({
  text = "",
  textAlign,
  lineHeight,
  width,
  color = "text.secondary",
  m,
  wordToHighlight,
  fontSize = { xs: 8, sm: 14, md: "18px", lg: "24px" },
  article,
}) => {
  const { palette } = useTheme();
  const highlightedText = highlightWords(
    text ? text : article,
    wordToHighlight,
    palette.primary.main,
    700
  );

  return (
    <Typography
      component={article && "div"}
      sx={{
        fontSize: fontSize,
        width,
        textAlign,
        lineHeight,
        m: m,
        color: color,
      }}
    >
      {article ? parse(`<p>${article}</p>`) : highlightedText}
      {/* {highlightedText || text} */}
    </Typography>
  );
};

export default Description;
