import { Box } from "@mui/material";
import React from "react";
import Lottie from "lottie-react";
import homeBanner from "../../../assets/animations/home-banner.json";
const BannerAnimation = () => {
  const bannerAnimation = {
    // width: { xs: 523, md: 523, lg: 523 },
    // height: { xs: 331, md: 331, lg: 331 },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <Box sx={bannerAnimation}>
      <Box
        component={Lottie}
        animationData={homeBanner}
        sx={{
          width: { xs: 240, md: 320, lg: 523 },
          height: { xs: 80, md: 120, lg: 180 },
        }}
      />
    </Box>
  );
};

export default BannerAnimation;
