import React from "react";
import { Box, Container } from "@mui/material";
import commonStyles from "../../../assets/styles/common";
import { useTranslation } from "react-i18next";
import Box01 from "../../common/boxes/Box01";

const Word0 = () => {
  const { t } = useTranslation();

  return (
    <Box component={"section"}>
      <Container
        maxWidth="container"
        // sx={{ px: commonStyles.px4 }}
        // bgcolor={"background.paper"}
        // borderRadius={commonStyles.borderRadius}
      >
        <Box01
          title={t("home.title0")}
          subTitle={t("home.description0")}
          wordToHighlight={t("home.wordToHighlight0")}
          btnText={t("home.btn-about")}
          to="./about"
          textAlign="center"
          alignItems="center"
          bgcolor={"background.paper"}
          borderRadius={commonStyles.borderRadius}
          py={commonStyles.py1.py}
          justifyContent={"center"}
        />
      </Container>
    </Box>
  );
};

export default Word0;
