import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";
import Description from "../texts/Description";
import { useTheme } from "@emotion/react";
import highlightWords from "../../../utils/highlightWords";

const Card05 = ({
  icon,
  title,
  description,
  wordToHighlight,
  width = { xs: "100%", md: "100%", lg: "100%" },
  mt,
  fontSize,
  px,
  py = commonStyles.py0.py,
}) => {
  const { palette } = useTheme();

  const highlightedText = highlightWords(
    title,
    wordToHighlight,
    palette.primary.main,
    700
  );
  return (
    <Stack
      sx={{
        width: width,
        rowGap: { xs: "6px", sm: "12px", md: "18px", lg: "24px" },
        bgcolor: "background.paper",
        px,
        py,
        borderRadius: commonStyles.borderRadius,
        mt,
        img: {
          width: { xs: 25, sm: 30, md: 60, lg: 80 },
          height: { xs: 25, sm: 30, md: 60, lg: 80 },
        },
      }}
    >
      {icon && <Box component={"img"} src={icon} alt={title} />}

      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: 14, sm: 24, md: "36px", lg: "48px" },
        }}
      >
        {highlightedText || title}
      </Typography>
      {description && (
        <Description
          text={description}
          // wordToHighlight={wordToHighlight}
          fontSize={fontSize}
        />
      )}
    </Stack>
  );
};

export default Card05;
