import { Box } from "@mui/material";
import React from "react";
import Container0 from "../../common/containers/Container0";
import Box01 from "../../common/boxes/Box01";
import { useTranslation } from "react-i18next";
const Welcome = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Container0>
        <Box01
          description={t("about.description0")}
          wordToHighlight={t("about.wordToHighlight0")}
          title={t("about.title0")}
          subTitle={t("about.sub-title0")}
          descriptionWidth={{ xs: "100%", lg: 1180 }}
        />
      </Container0>
    </Box>
  );
};

export default Welcome;
