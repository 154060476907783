import React from "react";
import WorkComponent from "../../components/portfolio/works/WorkComponent";
import WorkProvider from "../../context/WorkContext";
const Work = () => {
  return (
    <WorkProvider>
      <WorkComponent />
    </WorkProvider>
  );
};

export default Work;
