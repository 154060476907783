// Importing necessary components and dependencies
import { Box, Button, Dialog, DialogActions, Stack } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";
import { useTranslation } from "react-i18next";
import icons from "../../../assets/icons/icons";
import { useWorkContext } from "../../../context/WorkContext";
import CardDescription from "../../common/texts/CardDescription";

// Component definition
const Work2 = () => {
  const { work, open, setOpen, currentImg, setCurrentImg } = useWorkContext();
  // Translation hook for internationalization
  const { i18n, t } = useTranslation();
  const dir = i18n.dir();
  // Styling for the first button
  const btn0Style = {
    border: "1px solid ",
    borderInlineStart: "none",
    borderEndEndRadius: commonStyles.borderRadius,
    borderStartEndRadius: commonStyles.borderRadius,
    borderColor: "background.paper",
    // borderRadius: commonStyles.borderRadius,
    minWidth: { xs: 25, sm: 55, md: 90, lg: 131 },
    height: { xs: 420, sm: 540, md: 600, lg: 732 },
    ":hover": {
      bgcolor: "transparent",
    },
  };

  // Styling for the second button
  const btn1Style = {
    ...btn0Style,
    direction: dir === "ltr" ? "rtl" : "ltr",
  };

  // Function to handle next image
  const nextImg = () => {
    setCurrentImg((currentImg + 1) % work?.workImgs.length);
  };

  // Function to handle previous image
  const prevImg = () => {
    setCurrentImg(
      (currentImg - 1 + work.workImgs?.length) % work.workImgs?.length
    );
  };

  // Component rendering
  return (
    <Dialog fullScreen open={open} maxWidth="container">
      <Stack
        sx={{
          bgcolor: "background.img",
          height: "100vh",
          justifyContent: "space-between",
        }}
      >
        <DialogActions sx={{ py: commonStyles.py0.py }}>
          {/* Close button */}
          <Button
            disableRipple
            onClick={() => setOpen(false)}
            sx={{
              color: "background.paper",
              px: commonStyles.px,
              fontSize: { xs: 10, sm: 12, md: 18, lg: 24 },
              fontWeight: 500,
              ":hover": {
                bgcolor: "transparent",
              },
            }}
          >
            {t("portfolio.btn0")}
          </Button>
        </DialogActions>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: { xs: "center", lg: "center" },
          }}
        >
          {/* Show previous button or empty box */}
          {currentImg === 0 ? (
            <Button
              disableRipple
              sx={{
                minWidth: { xs: 32, sm: 48, md: 76, lg: 96 },

                ":hover": {
                  bgcolor: "transparent",
                },
              }}
              onClick={prevImg}
            >
              <Box
                component={"img"}
                src={
                  dir === "ltr" ? icons.arrowLeftWhite : icons.arrowRightWhite
                }
                sx={{
                  px: commonStyles.px,
                  width: { xs: 38, sm: 64, md: 96, lg: 160 },
                  height: { xs: 38, sm: 48, md: 76, lg: 160 },
                }}
              />
            </Button>
          ) : (
            <Button onClick={prevImg} disableRipple sx={btn0Style} />
          )}

          {/* Display current image and title  */}
          <Box
            component={"img"}
            src={work.workImgs[currentImg]}
            alt=""
            sx={{
              width: { xs: 270, sm: 430, md: 600, lg: 706 },
            }}
          />

          {/* Show next button or empty box */}
          {currentImg === work.workImgs.length - 1 ? (
            <Button
              disableRipple
              sx={{
                minWidth: { xs: 32, sm: 48, md: 76, lg: 96 },

                ":hover": {
                  bgcolor: "transparent",
                },
              }}
              onClick={nextImg}
            >
              <Box
                component={"img"}
                src={
                  dir === "ltr" ? icons.arrowRightWhite : icons.arrowLeftWhite
                }
                sx={{
                  px: commonStyles.px,
                  width: { xs: 38, sm: 64, md: 96, lg: 160 },
                  height: { xs: 38, sm: 48, md: 76, lg: 160 },
                }}
              />
            </Button>
          ) : (
            <Button onClick={nextImg} disableRipple sx={btn1Style} />
          )}
        </Box>
        <Box
          sx={{
            px: commonStyles.px3,
            py: commonStyles.py2,
            bgcolor: "background.img",
          }}
        >
          <CardDescription
            icon={work.icon}
            title={work.title}
            year={work.year}
            isWhite={true}
            avatarWidthHeight={{ xs: 24, sm: 36, md: 48, lg: 64 }}
          />
        </Box>
      </Stack>
    </Dialog>
  );
};

export default Work2;
