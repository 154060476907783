import { Box } from "@mui/material";
import React from "react";
import icons from "../../../assets/icons/icons";
import CardDescription from "../../common/texts/CardDescription";
import { useTranslation } from "react-i18next";
import Card05 from "../../common/cards/Card05";
import commonStyles from "../../../assets/styles/common";
import Container3 from "../../common/containers/Container3";
import { useWorkContext } from "../../../context/WorkContext";

const Work0 = () => {
  const { t } = useTranslation();
  const { work } = useWorkContext();
  return (
    <Box component={"section"}>
      <Container3>
        <CardDescription
          icon={work.icon}
          title={work.title}
          year={work.year}
          avatarWidthHeight={{ xs: 55, sm: 70, md: 85, lg: 100 }}
        />
        <Card05
          icon={icons.portfolio0}
          description={t("portfolio.description2")}
          title={t("portfolio.title2")}
          wordToHighlight={t("portfolio.wordToHighlight0")}
          width={"100%"}
          mt={commonStyles.py0.py}
          px={commonStyles.px6}
        />
      </Container3>
    </Box>
  );
};

export default Work0;
