import { Box } from "@mui/material";
import React from "react";
import Lottie from "lottie-react";
import mobileApps from "../../../assets/animations/mobile-apps.json";

const MobileAppsAnimation = () => {
  const mobileAppsAnimation = {
    // width: { xs: 509, md: 509, lg: 509 },
    // height: { xs: 640, md: 640, lg: 640 },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <Box sx={mobileAppsAnimation}>
      <Box
        component={Lottie}
        animationData={mobileApps}
        sx={{
          width: { xs: 320, md: 420, lg: 509 },
          height: { xs: 400, md: 520, lg: 640 },
        }}
      />
    </Box>
  );
};

export default MobileAppsAnimation;
