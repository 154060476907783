import React from "react";
import scrollToSection from "../../../utils/scrollToSection";
import { Box, Button } from "@mui/material";
import icons from "../../../assets/icons/icons";

const ButtonToSection = ({ elementRef }) => {
  const btnStyle = {
    bgcolor: "inherit",
    width: { xs: 32, md: 48, lg: 64 },
    height: { xs: 32, md: 48, lg: 64 },
    ":hover": { bgcolor: "inherit" },
  };
  return (
    <Button
      disableTouchRipple
      onClick={() => scrollToSection(elementRef)}
      sx={btnStyle}
    >
      <Box
        component={"img"}
        src={icons.arrowCircleBrokenDown}
        alt="arrow down"
        sx={{
          width: "inherit",
          height: "inherit",
        }}
      />
    </Button>
  );
};

export default ButtonToSection;
