import React from "react";
import HeaderLogo from "./HeaderLogo";
import { AppBar, Container, Toolbar, useScrollTrigger } from "@mui/material";
import commonStyles from "../../assets/styles/common";
import HeaderLarge from "./HeaderLarge";
import HeaderSmall from "./HeaderSmall";
import useDrawerToggle from "../../hooks/useDrawerToggle";
import HeaderDrawer from "./HeaderDrawer";

const Header = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 250,
  });
  const { isDrawerOpened, setIsDrawerOpened } = useDrawerToggle();
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          backgroundColor: !trigger ? "background.main" : "background.paper",
          px: commonStyles.px,
        }}
      >
        <Container maxWidth="container">
          {!trigger && <HeaderLogo trigger={trigger} />}
          <Toolbar variant="dense">
            <HeaderLarge trigger={trigger} />
            <HeaderSmall
              isDrawerOpened={isDrawerOpened}
              setIsDrawerOpened={setIsDrawerOpened}
            />
          </Toolbar>
        </Container>
      </AppBar>
      <HeaderDrawer
        isDrawerOpened={isDrawerOpened}
        setIsDrawerOpened={setIsDrawerOpened}
      />
    </>
  );
};

export default Header;
