import { Typography } from "@mui/material";
import React from "react";

const Description1 = ({
  text,
  textAlign,
  lineHeight,
  width,
  color = "text.secondary",
  m = "auto",
}) => {
  return (
    <Typography
      variant="body2"
      sx={{
        fontSize: { xs: 10, sm: 16, md: "22px", lg: "28px" },
        width,
        textAlign,
        lineHeight,
        m: m,
        color: color,
      }}
    >
      {text}
    </Typography>
  );
};

export default Description1;
