import React from "react";
import { NavLink } from "react-router-dom";
import "./NavBarLink.scss";

const NavBarLink = ({ to, title }) => {
  return (
    <NavLink
      className={({ isActive }) => (isActive ? "link active" : "link")}
      to={to}
      // end={end}
      // style={({ isActive }) => {
      //   return {
      //     color: isActive && "green",
      //     "&:hover": {
      //       color: "red",
      //     },
      //   };
      // }}
    >
      {title}
    </NavLink>
  );
};

export default NavBarLink;
