// const highlightWords = (string, wordsToHighlight, color) => {
//   const regex = new RegExp(`(${wordsToHighlight.join("|")})`, "gi");
//   const parts = string.split(regex);
//   return parts.map((part, index) => {
//     if (wordsToHighlight.includes(part)) {
//       console.log(part);

//       return (
//         <span key={index} style={{ color: color }}>
//           {part}
//         </span>
//       );
//     } else {
//       return part;
//     }
//   });
// };
// export default highlightWords;
const highlightWords = (string, wordToHighlight, color, fontWeight) => {
  const regex = new RegExp(`(${wordToHighlight})`, "gi");
  const parts = string.split(regex);
  return parts.map((part, index) => {
    if (part === wordToHighlight) {
      return (
        <span key={index} style={{ color: color, fontWeight: fontWeight }}>
          {part}
        </span>
      );
    } else {
      return part;
    }
  });
};
export default highlightWords;
