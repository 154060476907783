import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import images from "../../assets/images/images";

const RightsAndSocial = () => {
  const { t } = useTranslation();
  const socials = [
    {
      name: "linkedin",
      img: images.linkedin,
      href: "https://www.linkedin.com/",
    },
    {
      name: "instagram",
      img: images.instagram,
      href: "https://www.instagram.com/",
    },
    {
      name: "twitter",
      img: images.twitter,
      href: "https://twitter.com/",
    },
  ];
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: { xs: 8, sm: 10, md: "12px", lg: "14px" },
        }}
      >
        {t("footer.rights")}
      </Typography>
      <Box
        sx={{ display: "flex", gap: { xs: "10px", md: "24px", lg: "32px" } }}
      >
        {socials.map((item, index) => (
          <Link key={index} href={item.href} target="_blank">
            <Box
              component={"img"}
              src={item.img}
              alt={item.name}
              sx={{
                width: { xs: 12, sm: 16, md: 20, lg: 24 },
                height: { xs: 12, sm: 16, md: 20, lg: 24 },
              }}
            />
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default RightsAndSocial;
