import { Box } from "@mui/material";
import React from "react";
import images from "../../assets/images/images";
import navStyles from "../../assets/styles/nav";
import commonStyles from "../../assets/styles/common";
import { Link } from "react-router-dom";

const HeaderLogo = ({ trigger }) => {
  const { logo } = navStyles.containersHeight;
  if (!trigger) {
    return (
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Link>
          <Box
            sx={{
              height: logo,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "background.paper",
              borderRadius: commonStyles.borderRadius,
            }}
          >
            <Box
              component={"img"}
              src={images.logo0}
              alt="mountasher logo"
              sx={{
                width: { xs: "120px", md: "180x", lg: "222px" },
                height: { xs: "24px", md: "32px", lg: "40px" },
              }}
            />
          </Box>
        </Link>
      </Box>
    );
  } else {
    return (
      <Link to="./">
        <Box
          component={"img"}
          src={images.logo0}
          alt="mountasher logo"
          sx={{
            width: { xs: "120px", md: "148px", lg: "178px" },
            height: { xs: "24px", md: "28x", lg: "32px" },
          }}
        />
      </Link>
    );
  }
};

export default HeaderLogo;
