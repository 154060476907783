import React from "react";
import { Typography, useTheme } from "@mui/material";
import highlightWords from "../../../utils/highlightWords";

const SectionDescription = ({
  text,
  isFadeElement = false,
  isVisible,
  textAlign,
  width = { xs: "100%", md: "80%", lg: "55%" },
  wordToHighlight,
}) => {
  const { palette } = useTheme();
  const highlightedText = highlightWords(
    text,
    wordToHighlight,
    palette.primary.main
  );
  const sectionDescriptionStyle = {
    color: "text.primary",
    fontSize: { xs: 12, sm: 18, md: "36px", lg: "48px" },
    width,
    textAlign,
  };
  const fadeSectionDescriptionStyle = {
    ...sectionDescriptionStyle,
    opacity: isVisible ? 1 : 0,
    transition: "opacity 2.5s ease-in-out",
    textAlign: "center",
    minHeight: { xs: 80, md: 110, lg: 155 },
  };
  if (!isFadeElement) {
    return (
      <Typography variant="h6" component={"h6"} sx={sectionDescriptionStyle}>
        {highlightedText}
      </Typography>
    );
  } else {
    return (
      <Typography
        variant="h6"
        component={"h6"}
        sx={fadeSectionDescriptionStyle}
      >
        {highlightedText}
      </Typography>
    );
  }
};

export default SectionDescription;
