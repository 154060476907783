import React from "react";
import ContactComponent from "../../components/contact/ContactComponent";

const Contact = () => {
  return (
    <>
      <ContactComponent />
    </>
  );
};

export default Contact;
