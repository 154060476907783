import { Typography } from "@mui/material";
import React from "react";

const Title01 = ({ text, fontWeight = 700, textAlign = "center", width }) => {
  return (
    <Typography
      variant="h5"
      sx={{
        fontWeight: fontWeight,
        fontSize: { md: 24, lg: 32 },
        textAlign: textAlign,
        width,
        m: "auto",
      }}
    >
      {text}
    </Typography>
  );
};

export default Title01;
