import React from "react";
import { useTranslation } from "react-i18next";
import MainTitle from "../common/texts/MainTitle";
import AboutAnimation from "./animation/AboutAnimation";
import Welcome from "./welcome/Welcome";
import Vision from "./vision/Vision";
import WorkContact from "./work-contact/WorkContact";
import useMount from "../../hooks/useMount";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { componentFade } from "../../utils/animationsVariants";

const AboutComponent = () => {
  const { t } = useTranslation();
  useMount({ documentTitle: t("about.bar-title") });

  return (
    <>
      <Box
        component={motion.section}
        variants={componentFade}
        initial="hidden"
        animate="showComponets"
        exit="exit"
      >
        <MainTitle text={t("header.about")} />
        <AboutAnimation />
        <Welcome />
        <Vision />
        <WorkContact />
      </Box>
    </>
  );
};

export default AboutComponent;
