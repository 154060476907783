import { Box, Typography } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";

const Card06 = ({ text }) => {
  return (
    <Box
      sx={{
        width: { xs: "100%", lg: 460, container: 580 },
        bgcolor: "background.common",
        borderRadius: commonStyles.borderRadius,
        py: commonStyles.py0.py,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: 14, sm: 24, md: 36, lg: 48 },
          textAlign: "center",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default Card06;
