import { Box } from "@mui/material";
import React from "react";
import Box01 from "../common/boxes/Box01";
import { useTranslation } from "react-i18next";
import commonStyles from "../../assets/styles/common";
import Container3 from "../common/containers/Container3";

const Portfolio0 = () => {
  const { t } = useTranslation();
  return (
    <Box component={"section"}>
      <Container3>
        <Box01
          title={t("portfolio.title0")}
          subTitle={t("portfolio.sub-title0")}
          description={t("portfolio.description0")}
          wordToHighlight={t("portfolio.wordToHighlight0")}
          borderRadius={commonStyles.borderRadius}
          py={commonStyles.py0.py}
          alignItems={"center"}
          textAlign={"center"}
        />
      </Container3>
    </Box>
  );
};

export default Portfolio0;
