import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const Column = ({ title, links = [], items = [] }) => {
  return (
    <Stack
      sx={{
        rowGap: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        sx={{ fontSize: { xs: 10, sm: 12, md: "14px", lg: "16px" } }}
      >
        {title}
      </Typography>
      {links.length
        ? links.map((link, index) => (
            <Link
              key={index}
              component={RouterLink}
              to={link.to}
              sx={{
                textDecoration: "none",
                color: "text.primary",
                fontSize: { xs: 10, sm: 12, md: 14, lg: "16px" },
                fontWeight: 500,
              }}
            >
              {link.text}
            </Link>
          ))
        : items.map((item, index) => (
            <Typography
              key={index}
              variant="body2"
              sx={{ fontSize: { xs: 10, sm: 12, md: 14, lg: "16px" } }}
            >
              {item}
            </Typography>
          ))}
    </Stack>
  );
};

export default Column;
