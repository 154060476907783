import axios from "axios";
import { toast } from "react-toastify";

const sendMessage = async (values, actions) => {
  try {
    const response = await axios.post(`contact/send_message`, values);

    if (
      response?.data?.status === 1 &&
      response?.data?.message === "message sent successfully"
    ) {
      toast.success(response?.data?.message);
      actions.resetForm();
      console.log(response?.data?.message);
    }
  } catch (error) {
    toast.error(
      error?.message || error?.response?.date?.message || "Something went wrong"
    );
  }
};
export default sendMessage;
