import { Box, Stack } from "@mui/material";
import React from "react";
import images from "../../assets/images/images";
import { useTranslation } from "react-i18next";
import Description from "../common/texts/Description";

const LogoAndText = () => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        rowGap: {
          xs: "12px",
          sm: "18px",
          md: "24px",
          lg: "32px",
        },
        alignItems: { xs: "center", md: "stretch" },
        textAlign: { xs: "center", md: "start" },
      }}
    >
      <Box
        component={"img"}
        src={images.logo0}
        sx={{
          width: { xs: 90, sm: 120, md: 185, lg: 266 },
          height: { xs: 18, sm: 24, md: 38, lg: 48 },
        }}
      />
      <Description
        text={t("footer.word")}
        color="text.primary"
        width={{ xs: "80%", sm: "60%", md: "80%", lg: 450 }}
        fontSize={{ xs: 12, sm: 14, md: "18px", lg: "24px" }}
      />
    </Stack>
  );
};

export default LogoAndText;
