import { Box } from "@mui/material";
import React from "react";
import images from "../../../assets/images/images";
import { useTranslation } from "react-i18next";
import Container1 from "../../common/containers/Container1";

const Background = () => {
  const { i18n } = useTranslation();
  const dir = i18n.dir();
  return (
    <Box component={"section"}>
      <Container1>
        <Box
          component={"img"}
          src={dir === "ltr" ? images.backgroundLTR0 : images.backgroundRTL0}
          alt=""
          sx={{
            height: { xs: 200, md: 320, lg: 560 },
            width: "100%",
            objectFit: "contain",
          }}
        ></Box>
      </Container1>
    </Box>
  );
};

export default Background;
