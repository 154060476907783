import { Box } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";
import icons from "../../../assets/icons/icons";

const Dot01 = ({ isHasBtn, pb }) => {
  return (
    <Box
      sx={{
        pt: commonStyles.py0.py,
        pb,
        px: isHasBtn ? commonStyles.px5 : commonStyles.px,
        img: {
          width: { xs: 20, md: 30, lg: 40 },
          height: { xs: 20, md: 30, lg: 40 },
        },
      }}
    >
      <Box component={"img"} src={icons.dot01} alt="dot" />
    </Box>
  );
};

export default Dot01;
