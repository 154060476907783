import { Box, Stack, TextField } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";
import { useFormikContext } from "formik";

const Input0 = ({
  inputName,
  inputLabel,
  multiline,
  placeholder,
  type,
  bgcolor,
  borderColor = "common.inputsBorder",
  width,
}) => {
  const { values, handleChange, errors, touched } = useFormikContext();

  return (
    <Stack sx={{ gap: { xs: "12px", sm: "16px", md: "20px", lg: "24px" } }}>
      {inputLabel && (
        <Box
          component={"label"}
          htmlFor={inputName}
          sx={{ fontSize: { xs: 12, sm: 16, md: 24, lg: 32 }, fontWeight: 600 }}
        >
          {inputLabel}
        </Box>
      )}

      <TextField
        multiline={multiline}
        rows={0}
        name={inputName}
        id={inputName}
        value={values[inputName]}
        onChange={handleChange}
        type={type}
        placeholder={placeholder}
        error={errors?.[inputName] && touched?.[inputName]}
        helperText={
          errors?.[inputName] && touched?.[inputName] ? errors?.[inputName] : ""
        }
        sx={{
          "& input::placeholder": {
            color: "secondary.main",
            fontSize: { xs: 10, sm: 12, md: 18, lg: 24 },
          },
          border: "none",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: borderColor,
              borderRadius: commonStyles.borderRadius1,
            },
          },
          "& .MuiInputBase-root": {
            fontSize: { xs: 10, sm: 12, md: 18, lg: 24 },
            minHeight: { xs: 42, sm: 52, md: 64, lg: 72 },
            bgcolor: bgcolor,
          },
          "& .MuiInputBase-input": {
            paddingTop: 0,
            paddingBottom: 0,
            px: commonStyles.px,
          },
          width: width,
        }}
        InputProps={{
          style: {
            borderRadius: "20px",
          },
        }}
      />
    </Stack>
  );
};

export default Input0;
