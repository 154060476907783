const commonStyles = {
  borderRadius: { xs: "20px", md: "30px", lg: "40px" },
  borderRadius1: { xs: "10px", sm: "14px", md: "16px", lg: "20px" },
  py0: {
    py: { xs: "14px", sm: "24px", md: "36px", lg: "48px" },
  },
  py1: {
    py: { xs: "16px", sm: "28px", md: "48px", lg: "64px" },
  },
  py2: { xs: "18px", md: "24px", lg: "28px" },
  px: { xs: "10px", sm: "18px", md: "24px", lg: "56px" },
  px0: { xs: "24px", md: "36px", lg: "48px" },
  px1: { xs: "12px", sm: "18px", md: "32px", lg: "40px" },
  px2: { xs: "12px", md: "18px", lg: "24px" },
  px3: { xs: "12px", sm: "24px", md: "72px", lg: "104px" },
  px4: { xs: "12px", sm: "24px", md: "36px", lg: "64px" },
  px5: { xs: "28px", sm: "64px", md: "92px", lg: "148px" },
  px6: { xs: "24px", sm: "32px", md: "48px", lg: "88px" },
};
export default commonStyles;
