import { Container } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";

const Container1 = ({
  children,
  bgcolor,
  borderRadius,
  pb = commonStyles.py1.py,
}) => {
  return (
    <Container
      sx={{
        height: "100%",
        px: commonStyles.px,
        pt: commonStyles.py1.py,
        pb,
        bgcolor,
        borderRadius,
      }}
      maxWidth="container"
    >
      {children}
    </Container>
  );
};

export default Container1;
