import { Box } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";

const Card04 = ({ img, alt }) => {
  return (
    <Box
      sx={{
        width: { xs: 140, sm: 230, md: 400, lg: 581 },
        height: { xs: 300, md: 500, lg: 704 },
        pt: { xs: "25px", md: "50px", lg: "100px" },
        bgcolor: { xs: "transparent", lg: "background.paper" },
        display: "flex",
        justifyContent: "center",
        marginBottom: { xs: "25px", md: "100px", lg: "150px" },
        borderRadius: commonStyles.borderRadius,
        img: {
          height: { xs: 300, md: 525, lg: 754 },
        },
      }}
    >
      <Box component={"img"} src={img} alt={alt} />
    </Box>
  );
};

export default Card04;
