import { CssBaseline, ThemeProvider } from "@mui/material";
import "./App.css";
import mainRouter from "./utils/mainRouter";
import { useMainThemeContext } from "./context/MainThemeContext";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const App = () => {
  const { theme, mode } = useMainThemeContext();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <RouterProvider router={mainRouter} />
      <ToastContainer
        limit={1}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        draggable={false}
        theme={mode}
      />
    </ThemeProvider>
  );
};

export default App;
