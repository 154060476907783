import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Card01 from "../../common/cards/Card01";
import useLazyComponent from "../../../hooks/useLazyComponent ";
import Container4 from "../../common/containers/Container4";
import { useMainThemeContext } from "../../../context/MainThemeContext";

const Briefs = () => {
  const { ref, isVisible } = useLazyComponent();
  const { homeBriefs } = useMainThemeContext();
  const { t } = useTranslation();
  const briefs = [
    { text: t("home.brief.text0"), height: 220, delay: 0.1 },
    { text: t("home.brief.text1"), height: 180, delay: 0.2 },
    { text: t("home.brief.text2"), height: 140, delay: 0.3 },
  ];
  return (
    <Box ref={homeBriefs}>
      <Box ref={ref} component={"section"}>
        <Container4>
          {isVisible && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: { xs: "16px", md: 0 },
                transition: "all 1s",
                flexWrap: "wrap",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              {briefs.map((item, index) => (
                <Card01
                  key={index}
                  text={item.text}
                  height={item.height}
                  delay={item.delay}
                />
              ))}
            </Box>
          )}
        </Container4>
      </Box>
    </Box>
  );
};

export default Briefs;
