import React from "react";
import Banner from "./banner/Banner";
import Briefs from "./brief/Briefs";
import Background from "./background/Background";
import Word0 from "./words/Word0";
import MobileApps from "./mobile-apps/MobileApps";
import Word1 from "./words/Word1";
import Word2 from "./words/Word2";
import Phase from "./phase/Phase";
import { useTranslation } from "react-i18next";
import useMount from "../../hooks/useMount";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { componentFade } from "../../utils/animationsVariants";
const HomeComponent = () => {
  const { t } = useTranslation();
  useMount({ documentTitle: t("home.bar-title") });

  return (
    <>
      <Box
        component={motion.section}
        variants={componentFade}
        initial="hidden"
        animate="showComponets"
        exit="exit"
      >
        <Banner />
        <Briefs />
        <Background />
        <Word0 />
        <MobileApps />
        <Word1 />
        <Word2 />
        <Phase />
      </Box>
    </>
  );
};

export default HomeComponent;
