import { Box, Grid } from "@mui/material";
import React from "react";
import images from "../../../assets/images/images";
import Container0 from "../../common/containers/Container0";
import { useTranslation } from "react-i18next";
import Box01 from "../../common/boxes/Box01";
import MobileAppsAnimation from "./MobileAppsAnimation";
import Slider01 from "../../sliders/Slider01";
import commonStyles from "../../../assets/styles/common";
const MobileApps = () => {
  const { i18n, t } = useTranslation();
  const dir = i18n.dir();
  return (
    <>
      <Box
        component={"section"}
        sx={{
          position: "relative",
          backgroundImage:
            dir === "ltr"
              ? `url(${images.backgroundLTR1})`
              : `url(${images.backgroundRTL1})`,
          backgroundPositionX: "center",
          backgroundPositionY: "-100px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container0 pb="0px">
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid item xs={12} md={6}>
              <Box01
                description={t("home.mobile-app.description")}
                subTitle={t("home.mobile-app.sub-title")}
                title={t("home.mobile-app.title")}
                wordToHighlight={t("home.mobile-app.wordToHighlight")}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <MobileAppsAnimation />
            </Grid>
          </Grid>
        </Container0>
        <Box sx={{ display: { xs: "none", container: "block" } }}>
          <Slider01 dotPb={commonStyles.py0.py} />
        </Box>
        <Box sx={{ display: { container: "none" } }}>
          <Slider01 isHasNextBtn={true} dotPb={commonStyles.py0.py} />
        </Box>
      </Box>
    </>
  );
};

export default MobileApps;
