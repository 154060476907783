import { Box, Button } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";

const WorkImg = ({ src, alt, index, onClick }) => {
  return (
    <Button
      disableRipple
      sx={{
        width: index === 0 ? "100%" : "48%",
        height: index === 0 ? { xs: 240, sm: 360, md: 480, lg: 576 } : "100%",
        // objectFit: "cover",
        borderRadius: commonStyles.borderRadius,
        // bgcolor: "background.img",
        ":hover": {
          // bgcolor: "background.img",
        },
      }}
      onClick={onClick}
    >
      <Box
        component={"img"}
        src={src}
        alt={alt}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: commonStyles.borderRadius,
          // bgcolor: "background.img",
        }}
      />
    </Button>
  );
};

export default WorkImg;
