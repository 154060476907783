import { Stack } from "@mui/material";
import React from "react";
import SectionTitle from "../texts/SectionTitle";
import SectionDescription from "../texts/SectionDescription";
import Button01 from "../buttons/Button01";
import Description from "../texts/Description";
import commonStyles from "../../../assets/styles/common";

const Box01 = ({
  title,
  description,
  sectionDescriptionWidth = { xs: "100%", md: "100%", lg: "100%" },
  btnText,
  to,
  subTitle,
  textAlign,
  alignItems,
  bgcolor,
  borderRadius,
  py,
  wordToHighlight,
  descriptionWidth,
  px = commonStyles.px0,
  justifyContent,
  width,
  height,
  descriptionWordToHighlight,
  article,
  children,
}) => {
  return (
    <Stack
      sx={{
        rowGap: { xs: "12px", md: "18px", lg: "24px" },
        alignItems: alignItems,
        justifyContent,
        bgcolor: bgcolor,
        borderRadius: borderRadius,
        py,
        px,
        width,
        height,
      }}
    >
      {title && <SectionTitle text={title} />}
      <SectionDescription
        text={subTitle}
        textAlign={textAlign}
        width={sectionDescriptionWidth}
        wordToHighlight={wordToHighlight}
      />
      {description && (
        <Description
          text={description}
          width={descriptionWidth}
          wordToHighlight={descriptionWordToHighlight || wordToHighlight}
          textAlign={textAlign}
          // article={article}
        />
      )}
      {article && (
        <Description
          // text={description}
          width={descriptionWidth}
          wordToHighlight={descriptionWordToHighlight || wordToHighlight}
          textAlign={textAlign}
          article={article}
        />
      )}
      {btnText && <Button01 text={btnText} to={to} />}
      {children}
    </Stack>
  );
};

export default Box01;
