import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Button01 = ({
  text,
  to,
  onClick,
  height = { xs: 28, sm: 35, md: 48, lg: 56 },
  minWidth,
}) => {
  return (
    <Button
      type={!to ? "sumbit" : "button"}
      disableTouchRipple
      component={to && Link}
      to={to && to}
      variant="contained"
      sx={{
        bgcolor: "background.btns",
        boxShadow: 0,
        width: { xs: 120, sm: 135, md: 150, lg: 196 },
        minWidth,
        height: height,
        borderRadius: { xs: "30px", md: "60px", lg: "100px" },
        fontWeight: 700,
        fontSize: { xs: 10, sm: "12px", md: "18px", lg: "24px" },
        ":hover": {
          bgcolor: "background.btns",
          boxShadow: 0,
        },
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default Button01;
