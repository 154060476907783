const logoContainersHeight = {
  lg: 104,
  md: 84,
  xs: 44,
};
const linksContainersHeight = {
  lg: 82,
  md: 52,
  xs: 32,
};

const navStyles = {
  containersHeight: {
    logo: logoContainersHeight,
    links: linksContainersHeight,
  },
  appLayoutPy: {
    xs: logoContainersHeight.xs + linksContainersHeight.xs + "px",
    md: logoContainersHeight.md + linksContainersHeight.md + "px",
    lg: logoContainersHeight.lg + linksContainersHeight.lg + "px",
  },
  triggerContainersHeight: {
    lg: 119,
    md: 90,
    xs: 80,
  },
  py: {
    lg: "16px",
    md: "12px",
    xs: "10px",
  },
  triggerPy: {
    lg: "37px",
    md: "28px",
    xs: "24px",
  },
  gap: { xs: "28px", md: "32px", lg: "58px" },
};
export default navStyles;
