import { Box, Button, Container } from "@mui/material";
import React, { useState } from "react";
import commonStyles from "../../assets/styles/common";
import { useTranslation } from "react-i18next";
import Card02 from "../common/cards/Card02";
import Card03 from "../common/cards/Card03";
import images from "../../assets/images/images";
import Dot01 from "../common/dots/Dot01";
import icons from "../../assets/icons/icons";

const Slider01 = ({ isHasNextBtn, dotPb }) => {
  const isHasBtn = isHasNextBtn;
  const { t, i18n } = useTranslation();
  const dir = i18n.dir();
  const sliders = [
    {
      title: t("home.mobile-app.title0"),
      description0: t("home.mobile-app.description0"),
      img: images.mobileApp0,
    },
    {
      title: t("home.mobile-app.title1"),
      description0: t("home.mobile-app.description1"),
      img: images.mobileApp1,
    },
    {
      title: t("home.mobile-app.title2"),
      description0: t("home.mobile-app.description2"),
      img: images.mobileApp2,
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleNextClick = () => {
    setCurrentIndex((currentIndex + 1) % sliders.length);
  };
  const activeSlide = sliders[currentIndex];
  const inactiveSlides = sliders.filter(
    (slider, index) => index !== currentIndex
  );
  const setActiveSlide = (active) => {
    setTimeout(() => {
      setCurrentIndex(sliders.indexOf(active));
    }, 300);
  };

  return (
    <Box
      sx={{
        overflow: { xs: "hidden", md: "hidden", lg: "hidden" },
        maxWidth: "container",
      }}
    >
      <Container
        maxWidth="container"
        sx={{
          paddingInlineStart: commonStyles.px3,
          paddingInlineEnd: { xs: 0 },
        }}
      >
        <Box
          sx={{
            width: { xs: "max-content", md: "max-content", lg: "max-content" },
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: { xs: "8px", sm: "16px", md: "24px", lg: "32px" },
          }}
        >
          {isHasBtn && (
            <Button
              disableTouchRipple
              onClick={handleNextClick}
              sx={{
                minWidth: { xs: 30, md: 60, lg: 96 },
                ":hover": {
                  bgcolor: "transparent",
                },
              }}
            >
              <Box
                component={"img"}
                src={dir === "ltr" ? icons.arrowLeft : icons.arrowRight}
                alt="next"
                sx={{
                  width: { xs: 30, md: 60, lg: 96 },
                  height: { xs: 30, md: 60, lg: 96 },
                }}
              />
            </Button>
          )}
          <Card02
            img={activeSlide.img}
            alt={activeSlide.title}
            title={activeSlide.title}
            description={activeSlide.description0}
          />
          {isHasBtn
            ? inactiveSlides.map((item, index) => (
                <Button
                  disableTouchRipple
                  onClick={() => setActiveSlide(item)}
                  key={index}
                  sx={{
                    borderRadius: commonStyles.borderRadius,
                    color: "text.primary",
                  }}
                >
                  <Card02
                    img={item.img}
                    alt={item.title}
                    title={item.title}
                    description={item.description0}
                  />
                </Button>
              ))
            : inactiveSlides.map((item, index) => (
                <Button
                  disableTouchRipple
                  onClick={() => setActiveSlide(item)}
                  key={index}
                  sx={{
                    borderRadius: commonStyles.borderRadius,
                    color: "text.primary",
                  }}
                >
                  <Card03 img={item.img} title={item.title} alt={item.title} />
                </Button>
              ))}
        </Box>
        <Dot01 isHasBtn={isHasNextBtn} pb={dotPb} />
      </Container>
    </Box>
  );
};

export default Slider01;
