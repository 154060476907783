import { Box, Container } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";
import { useTranslation } from "react-i18next";
import Box02 from "../../common/boxes/Box02";

const Word1 = () => {
  const { t } = useTranslation();
  return (
    <Box component={"section"}>
      <Container
        maxWidth={"container"}
        sx={{ px: commonStyles.px4, py: { xs: 0 } }}
      >
        <Box02
          borderRadius={commonStyles.borderRadius}
          bgcolor={"background.common"}
          py={commonStyles.py1.py}
          title={t("home.title1")}
          subTitle={t("home.sub-title1")}
          description={t("home.description1")}
          wordToHighlight={t("home.wordToHighlight1")}
          descriptionWidth={{ xs: "100%", md: "100%", lg: "100%" }}
          descriptionM={0}
          // height={{ xs: 820, md: 820, lg: 820 }}
        />
      </Container>
    </Box>
  );
};

export default Word1;
