import React from "react";
import Card07 from "../common/cards/Card07";
import { Box } from "@mui/material";
import Container3 from "../common/containers/Container3";
import images from "../../assets/images/images";
import icons from "../../assets/icons/icons";
const Portfolio2 = () => {
  const portfolios = [
    {
      id: 1,
      image: images.mycash0,
      title: "myCash - ماي كاش",
      year: 2022,
      icon: icons.mycash0,
    },
    {
      id: 2,
      image: images.fatoorah0,
      title: "Fatoorah App - تطبيق فاتورة",
      year: 2021,
      icon: icons.fatoorah0,
    },
  ];
  return (
    <Box component={"section"}>
      <Container3>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: { xs: "12px", sm: "18px", md: "24px", lg: "32px" },
          }}
        >
          {portfolios?.map((item, index) => (
            <Card07
              key={index}
              icon={item.icon}
              image={item.image}
              title={item.title}
              year={item.year}
              id={item.id}
            />
          ))}
        </Box>
      </Container3>
    </Box>
  );
};

export default Portfolio2;
