import { Button } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useMainThemeContext } from "../../../context/MainThemeContext";

const BtnChangeLang = () => {
  const { changeLanguage } = useMainThemeContext();

  return (
    <Button
      disableTouchRipple
      variant="text"
      sx={{
        fontSize: { xs: 20, md: 18, lg: 24 },
        fontWeight: 700,
        cursor: "pointer",
        color: "text.primary",
        ":hover": {
          backgroundColor: "initial",
          color: "initial",
        },
      }}
      onClick={() => changeLanguage()}
    >
      {t("header.lang")}
    </Button>
  );
};

export default BtnChangeLang;
