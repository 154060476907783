import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home/Home";
import About from "../pages/about/About";
import Services from "../pages/services/Services";
import MainLayout from "../components/layouts/MainLayout";
import Portfolio from "../pages/portfolio/Portfolio";
import Work from "../pages/portfolio/Work";
import Contact from "../pages/contact/Contact";
import TermsOfService from "../pages/legal/TermsOfService";
import PrivacyPolicy from "../pages/legal/PrivacyPolicy";
import CookiesPolicy from "../pages/legal/CookiesPolicy";
import Careers from "../pages/careers/Careers";
import Job from "../pages/careers/Job";
import PageNotFound from "../components/errors/PageNotFound";

const mainRouter = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <PageNotFound />,
    children: [
      { index: true, element: <Home /> },
      { path: "about", element: <About /> },
      { path: "services", element: <Services /> },
      { path: "portfolio", element: <Portfolio /> },
      { path: "portfolio/:id", element: <Work /> },
      { path: "contact", element: <Contact /> },
      // { path: "careers", element: <Careers /> },
      // { path: "careers/:id", element: <Job /> },
      // { path: "terms", element: <TermsOfService /> },
      // { path: "privacy-policy", element: <PrivacyPolicy /> },
      // { path: "cookies-policy", element: <CookiesPolicy /> },
    ],
  },
]);
export default mainRouter;
