import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../header/Header";
import navStyles from "../../assets/styles/nav";
import Footer from "../footer/Footer";
const MainLayout = ({ children, error = false }) => {
  const { appLayoutPy } = navStyles;
  return (
    <>
      <Box sx={{ bgcolor: "background.main", minHeight: "100vh" }}>
        <Header />
        <Box component={"main"} sx={{ pt: appLayoutPy }}>
          {error ? (
            children
          ) : (
            // <AnimatePresence >
            //   <Outlet key={location.pathname} />
            // </AnimatePresence>
            <Outlet />
          )}
        </Box>
        <Footer />
      </Box>
    </>
  );
};
export default MainLayout;
