import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
const useDrawerToggle = () => {
  const urlPathname = useLocation();
  const { pathname } = urlPathname;
  const { i18n } = useTranslation();
  const direction = i18n.dir();
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  useEffect(() => {
    setIsDrawerOpened(false);
    return () => {
      setIsDrawerOpened(false);
    };
  }, [pathname, direction]);

  return { isDrawerOpened, setIsDrawerOpened };
};
export default useDrawerToggle;
