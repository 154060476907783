import { dir } from "i18next";
import { useEffect, useState } from "react";

const useMount = ({ documentTitle }) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    document.title = documentTitle;
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });

    return () => {
      setIsMounted(false);
    };
  }, [dir()]);

  return { isMounted };
};

export default useMount;
