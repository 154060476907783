import { Typography } from "@mui/material";
import React from "react";
const Title02 = ({ text, fontWeight = 700, textAlign = "center", width }) => {
  return (
    <Typography
      variant="h5"
      sx={{
        fontWeight: fontWeight,
        fontSize: { xs: 14, md: 20, lg: 28 },
        textAlign: textAlign,
        width,
        m: "auto",
      }}
    >
      {text}
    </Typography>
  );
};

export default Title02;
