import React from "react";
import commonStyles from "../../../assets/styles/common";
import { Button, Card, CardMedia } from "@mui/material";
import { NavLink } from "react-router-dom";
import CardDescription from "../texts/CardDescription";

const Card07 = ({ image, icon, title, year, id }) => {
  return (
    <>
      <Card
        sx={{
          width: { xs: "100%", lg: 460, container: 584 },
          // height: { xs: 832, md: 832, lg: 832 },
          boxShadow: 0,
          bgcolor: "transparent",
        }}
      >
        <Button
          component={NavLink}
          to={`${id}`}
          disableRipple
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "14px", sm: "18px", md: "22px", lg: "26px" },
            ":hover": {
              bgcolor: "transparent",
            },
          }}
        >
          <CardMedia
            component="img"
            image={image}
            alt={title}
            sx={{
              height: { xs: 460, sm: 580, md: 600, lg: 720 },
              borderRadius: commonStyles.borderRadius,
            }}
          />
          <CardDescription
            icon={icon}
            title={title}
            year={year}
            avatarWidthHeight={{ xs: 55, sm: 70, md: 85, lg: 100 }}
          />
        </Button>
      </Card>
    </>
  );
};

export default Card07;
