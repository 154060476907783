import React from "react";
import AboutComponent from "../../components/about/AboutComponent";

const About = () => {
  return (
    <>
      <AboutComponent />
    </>
  );
};

export default About;
