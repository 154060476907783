import React, { createContext, useContext } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const MainContext = createContext();

const MainProvider = ({ children }) => {
  const { i18n } = useTranslation();

  axios.defaults.baseURL = "https://api.mountasher.sa/api/";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["lang"] = i18n.language;
  ///////////////////////////////////////////////////

  return <MainContext.Provider value={{}}>{children}</MainContext.Provider>;
};
// make sure use
export default MainProvider;
export const useMainContext = () => {
  return useContext(MainContext);
};
