import { Box, Typography } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";
import Container0 from "../containers/Container0";
const MainTitle = ({ text }) => {
  return (
    <Box component={"section"}>
      <Container0>
        <Typography
          variant="h2"
          sx={{
            fontSize: { sm: 18, md: 24, lg: 36 },
            py: commonStyles.py0.py,
            bgcolor: "background.common",
            borderRadius: commonStyles.borderRadius,
            textTransform: "capitalize",
          }}
        >
          {text}
        </Typography>
      </Container0>
    </Box>
  );
};

export default MainTitle;
