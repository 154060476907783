import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import commonStyles from "../../../assets/styles/common";
import { motion } from "framer-motion";

const Card01 = ({ text, height, delay }) => {
  return (
    <Stack
      sx={{
        // boxShadow: "0",
        // bgcolor: "inherit",
        width: { xs: "100%", sm: "100%", md: 250, lg: 330, container: 400 },
        gap: { xs: "24px", md: "24px", lg: "24px" },
        // height: { xs: 420, md: 420, lg: 420 },
      }}
    >
      <Box
        component={motion.div}
        sx={{
          height: height,
          bgcolor: "background.common",
          borderRadius: commonStyles.borderRadius,
        }}
        initial={{ height: 0 }}
        animate={{ height: height }}
        transition={{ duration: 1, ease: "easeIn", delay: delay }}
      />
      <Typography
        sx={{
          fontSize: { xs: 18, md: 24, lg: 32, container: 40 },
          fontWeight: 600,
          // width: { xs: 336, md: 336, lg: "336px" },
          px: commonStyles.px0,
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default Card01;
