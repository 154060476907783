import { Box } from "@mui/material";
import React from "react";
import HeaderLogo from "./HeaderLogo";
import HeaderLinks from "./HeaderLinks";
import BtnChangeLang from "../common/buttons/BtnChangeLang";
import navStyles from "../../assets/styles/nav";

const HeaderLarge = ({ trigger }) => {
  const { links } = navStyles.containersHeight;
  return (
    <Box
      sx={{
        width: "100%",
        height: trigger ? navStyles.triggerContainersHeight : links,
        py: !trigger ? navStyles.py : navStyles.triggerPy,
        display: { xs: "none", md: "flex" },
        justifyContent: trigger ? "space-between" : "center",
        alignItems: "flex-start",
        gap: navStyles.gap,
        transition: "ease-in-out all .4s",
      }}
    >
      {trigger && <HeaderLogo trigger={trigger} />}
      <HeaderLinks trigger={trigger} />

      <BtnChangeLang />
    </Box>
  );
};

export default HeaderLarge;
