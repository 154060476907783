import { List, ListItem, Drawer, IconButton } from "@mui/material";
import React from "react";
import HeaderLinks from "./HeaderLinks";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import BtnChangeLang from "../common/buttons/BtnChangeLang";

const drawerWidth = 300;

const HeaderDrawer = ({ isDrawerOpened, setIsDrawerOpened }) => {
  const listItemStyle = {
    mb: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <>
      <Drawer
        // variant="persistent"
        open={isDrawerOpened}
        sx={{
          display: { xs: "block", md: "none" },
          width: drawerWidth,
          flexShrink: 0,

          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            // bgcolor: "secondary.main",
            minHeight: 500,
          },
        }}
        anchor={"right"}
      >
        <List
          sx={{
            my: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ListItem sx={listItemStyle}>
            <IconButton
              disableRipple
              onClick={() => setIsDrawerOpened(!isDrawerOpened)}
              sx={{
                color: "primary.main",
                transition: "all .2s",
                display: { xs: "flex", lg: "none" },
                ":hover": {
                  bgcolor: "transparent",
                  color: "primary.main",
                },
              }}
            >
              {isDrawerOpened ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </ListItem>
          <ListItem sx={listItemStyle}>
            <HeaderLinks />
          </ListItem>
          <ListItem sx={listItemStyle}>
            <BtnChangeLang />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default HeaderDrawer;
