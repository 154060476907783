import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Box01 from "../common/boxes/Box01";
import Container3 from "../common/containers/Container3";
import commonStyles from "../../assets/styles/common";

const Services6 = () => {
  const { t } = useTranslation();
  return (
    <Box component={"section"}>
      <Container3 pb={commonStyles.py0.py}>
        <Box01
          btnText={t("home.btn-about")}
          bgcolor={"background.paper"}
          subTitle={t("services.sub-title3")}
          title={t("services.title3")}
          wordToHighlight={t("services.wordToHighlight3")}
          to={"/portfolio"}
          borderRadius={commonStyles.borderRadius}
          py={commonStyles.py0.py}
          sectionDescriptionWidth={{ xs: "100%", lg: 1000 }}
        />
      </Container3>
    </Box>
  );
};

export default Services6;
