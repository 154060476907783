import React from "react";
import { useTranslation } from "react-i18next";
import MainTitle from "../../common/texts/MainTitle";
import Work0 from "./Work0";
import Portfolio3 from "../Portfolio3";
import Work1 from "./Work1";
import useMount from "../../../hooks/useMount";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { componentFade } from "../../../utils/animationsVariants";

const WorkComponent = () => {
  const { t } = useTranslation();
  useMount({ documentTitle: t("portfolio.bar-title") });

  return (
    <Box
      component={motion.section}
      variants={componentFade}
      initial="hidden"
      animate="showComponets"
      exit="exit"
    >
      <MainTitle text={t("portfolio.main-title")} />
      <Work0 />
      <Work1 />
      <Portfolio3 />
    </Box>
  );
};

export default WorkComponent;
