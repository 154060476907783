import React from "react";
import { Box } from "@mui/material";
import Box01 from "../../common/boxes/Box01";
import { useTranslation } from "react-i18next";
import commonStyles from "../../../assets/styles/common";
import Container4 from "../../common/containers/Container4";

const WorkContact = () => {
  const { t } = useTranslation();
  return (
    <Box component={"section"}>
      <Container4 pb={commonStyles.py0.py}>
        <Box01
          btnText={t("header.contact")}
          bgcolor={"background.common"}
          subTitle={t("about.sub-title2")}
          title={t("about.title2")}
          wordToHighlight={t("about.wordToHighlight1")}
          to={"/contact"}
          borderRadius={commonStyles.borderRadius}
          py={commonStyles.py0.py}
          sectionDescriptionWidth={{ xs: "100%", lg: 1000 }}
        />
      </Container4>
    </Box>
  );
};

export default WorkContact;
