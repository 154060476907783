import { Box } from "@mui/material";
import React from "react";
import Lottie from "lottie-react";
import about0 from "../../../assets/animations/about0.json";
import images from "../../../assets/images/images";
import Container0 from "../../common/containers/Container0";
const AboutAnimation = () => {
  return (
    <Container0>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // backgroundImage: `url(${images.background2})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative",
          height: { xs: 150, sm: 220, md: 280, lg: 380 },
        }}
      >
        <Box
          component={Lottie}
          animationData={about0}
          sx={{
            width: { xs: 275, sm: 425, md: 545, lg: 545 },
            height: { xs: 180, sm: 260, md: 400, lg: 500 },
            position: "absolute",
          }}
        />
      </Box>
    </Container0>
  );
};

export default AboutAnimation;
