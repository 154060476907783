import arrowCircleBrokenDown from "./arrow-circle-broken-down.svg";
import dot01 from "./dot01.svg";
import phase01 from "./phase01.svg";
import phase02 from "./phase02.svg";
import phase03 from "./phase03.svg";
import phase04 from "./phase04.svg";
import phase05 from "./phase05.svg";
import phase06 from "./phase06.svg";
import vision from "./vision.svg";
import services from "./services.svg";
import arrowLeft from "./arrow-left.svg";
import arrowLeftWhite from "./arrow-left-white.svg";
import arrowRight from "./arrow-right.svg";
import arrowRightWhite from "./arrow-right-white.svg";
import mycash0 from "./mycash0.svg";
import fatoorah0 from "./fatoorah0.svg";
import portfolio0 from "./portfolio0.svg";
import careers0 from "./careers0.svg";
import careers1 from "./careers1.svg";
import careers2 from "./careers2.svg";
import careers3 from "./careers3.svg";
import upload0 from "./upload0.svg";

const icons = {
  arrowCircleBrokenDown,
  dot01,
  phase01,
  phase02,
  phase03,
  phase04,
  phase05,
  phase06,
  vision,
  services,
  arrowLeft,
  arrowRight,
  mycash0,
  portfolio0,
  fatoorah0,
  arrowLeftWhite,
  arrowRightWhite,
  careers0,
  careers1,
  careers2,
  careers3,
  upload0,
};
export default icons;
