import React from "react";
import { useTranslation } from "react-i18next";
import MainTitle from "../common/texts/MainTitle";
import Portfolio0 from "./Portfolio0";
import Portfolio1 from "./Portfolio1";
import Portfolio2 from "./Portfolio2";
import Portfolio3 from "./Portfolio3";
import useMount from "../../hooks/useMount";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { componentFade } from "../../utils/animationsVariants";

const PortfolioComponent = () => {
  const { t } = useTranslation();
  useMount({ documentTitle: t("portfolio.bar-title") });

  return (
    <>
      <Box
        component={motion.section}
        variants={componentFade}
        initial="hidden"
        animate="showComponets"
        exit="exit"
      >
        <MainTitle text={t("portfolio.main-title")} />
        <Portfolio0 />
        <Portfolio1 />
        <Portfolio2 />
        <Portfolio3 />
      </Box>
    </>
  );
};

export default PortfolioComponent;
