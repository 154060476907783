import { Box } from "@mui/material";
import React from "react";
import Card05 from "../../common/cards/Card05";
import icons from "../../../assets/icons/icons";
import { useTranslation } from "react-i18next";
import Container4 from "../../common/containers/Container4";
import commonStyles from "../../../assets/styles/common";

const Vision = () => {
  const { t } = useTranslation();
  return (
    <Box component={"section"}>
      <Container4>
        <Card05
          icon={icons.vision}
          description={t("about.description1")}
          title={t("about.title1")}
          wordToHighlight={t("about.wordToHighlight0")}
          width={"100%"}
          px={commonStyles.px3}
        />
      </Container4>
    </Box>
  );
};

export default Vision;
